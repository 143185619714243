import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authActions";
import { LOGOUT_DURATION } from "../../config/config";

const setLoginTimestamp = () => {
  const currentTimestamp = new Date().getTime();
  localStorage.setItem("loginTimestamp", currentTimestamp);
};

const calculateRemainingTime = () => {
  const loginTimestamp = localStorage.getItem("loginTimestamp");
  if (!loginTimestamp) return 0;

  const currentTime = new Date().getTime();
  const elapsedTime = currentTime - loginTimestamp;
  return LOGOUT_DURATION.timeOut - elapsedTime;
};

const useAutoLogout = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) return;

    setLoginTimestamp(); // Set initial timestamp on login

    const resetTimestamp = () => setLoginTimestamp();

    // Attach event listeners for user interactions
    window.addEventListener("mousemove", resetTimestamp);
    window.addEventListener("keydown", resetTimestamp);
    window.addEventListener("click", resetTimestamp);

    const intervalId = setInterval(() => {
      const remainingTime = calculateRemainingTime();

      if (remainingTime <= 0) {
        dispatch(logout());
        clearInterval(intervalId);
      }
    }, 1000); // Check every second

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("mousemove", resetTimestamp);
      window.removeEventListener("keydown", resetTimestamp);
      window.removeEventListener("click", resetTimestamp);
    };
  }, [isAuthenticated, dispatch]);
};

export default useAutoLogout;
