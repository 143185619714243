// import React from 'react'
import "./loader.css";


function Loader() {
  return (
    <div className='loader-wraper'>
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loader
