import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './DocumentPreview.css';

// Set the workerSrc to match the installed pdfjs-dist version
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf-2.12.313/pdf.worker.min.js`;

const DocumentPreview = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false); // Hide loader once the document is fully loaded
  };

  const goToPreviousPage = () => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  };

  // Disable right-click functionality
  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault(); // Prevent context menu
    };

    const pdfContainer = document.querySelector('.pdf-preview-container');
    pdfContainer.addEventListener('contextmenu', disableRightClick);

    // Cleanup event listener when component unmounts
    return () => {
      pdfContainer.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);

  return (
    <div className="pdf-preview-container">
      {loading && <div className="loader">Loading file...</div>} {/* Loader */}
      <Document
        file={file}
        onLoadSuccess={onLoadSuccess}
        onLoadError={(error) => {
          console.error(error);
          setLoading(false); // Hide loader if there's an error
        }}
      >
        <Page 
          pageNumber={pageNumber} 
          width={1200}  // Adjust this value to increase the width of the PDF preview
        />
      </Document>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button 
          onClick={goToPreviousPage} 
          disabled={pageNumber <= 1} 
          className="pagination-button"
        >
          Previous
        </button>
        <span className="page-number">
          Page {pageNumber} of {numPages}
        </span>
        <button 
          onClick={goToNextPage} 
          disabled={pageNumber >= numPages} 
          className="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DocumentPreview;
